import { liveRates } from "@launerlondon/currency";
import {
	currencies,
	findSku,
	fmtSku,
	getPaymentCurrencyByCountry,
	prices,
} from "@launerlondon/shared";
import calculateContextualPricing from "./lib/calculateContextualPricing";
import { CartItem, CartItemSnap, fmtCartItem } from "./lib/cartItem";
import { fmtSearchQuery } from "./lib/util";
import skus from "./skus";
import {
	Product,
	ProductImage,
	ProductRaw,
	ProductSnap,
	ProductSnapRaw,
} from "./types";
export type {
	CartItem,
	CartItemOption,
	CartItemSnap,
	CartItemSnapOption,
} from "./lib/cartItem";
export * from "./lib/util";
export * from "./types";
export * from "./prices";
export { fmtCartItem };

function fmtImage(image: ProductImage | string) {
	if (typeof image !== "string") {
		return image;
	}
	const res = image.match(/(\d+x\d+)/)?.[0] || "1280x1280";
	return {
		large: image, //image.replace(res, res),
		medium: image.replace(res, "1280x1280"),
		thumb: image.replace(res, "640x640"),
	};
}

function getModelImage(sku: string, gallery: ProductImage[]) {
	let model = `${sku}_20`;
	if (findSku(sku, skus.withInternalImage)) model = `${sku}_10`;
	return gallery.find(({ thumb }) => thumb.match(model));
}

function fmtProduct(product: ProductRaw) {
	const gbpPrice =
		product.prices.find((p) => p.currency === "GBP")?.value || 0;

	const getPrice = (currency: keyof typeof prices) => {
		const net = currency === "GBP" ? gbpPrice : gbpPrice / 1.2;
		const { markup, rate } = prices[currency];
		return net * markup * rate;
	};

	product.images.gallery = product.images.gallery.map(fmtImage);
	if (!product.images.product) {
		product.images.product = product.images.gallery.shift();
	}

	product.options = product.options || {};
	if (!product.options.swatches) product.options.swatches = [];

	product.prices = [
		{ currency: "GBP", value: getPrice("GBP") },
		{ currency: "BASE", value: getPrice("BASE") },
		{ currency: "AUD", value: getPrice("AUD") },
		{ currency: "CNY", value: getPrice("CNY") },
		{ currency: "EUR", value: getPrice("EUR") },
		{ currency: "HKD", value: getPrice("HKD") },
		{ currency: "USD", value: getPrice("USD") },
	];

	product.multipliers = {
		croc: product.multipliers?.croc || 0,
		lizard: product.multipliers?.lizard || 0,
		python: product.multipliers?.python || 0,
		lettering_short: 0 / gbpPrice,
		lettering_long: 0 / gbpPrice,
		gold_corners_single: 80 / gbpPrice,
		gold_corners_double: 150 / gbpPrice,
		shielding: 35 / gbpPrice,
		emblem_crystal: 145 / gbpPrice,
	};

	if (!product.category && product.categories[0]) {
		product.category = product.categories[0].split("> ").pop()!;
	}

	return product;
}

export const data: Promise<ProductRaw[]> = import("./data.json")
	.then((m) => m.default as ProductRaw[])
	.then((products) => {
		return products
			.filter((p) => !p.hidden && !skus.hidden.includes(p.sku))
			.map(fmtProduct);
	});

const snaps = (async function () {
	return (await data)
		.reduce<ProductSnapRaw[]>((p, c) => {
			c.categories.forEach((cat) => {
				p.push({
					private: c.private,
					sku: c.sku,
					slug: c.slug,
					name: c.name,
					createdAt: c.createdAt
						? new Date(c.createdAt.toString())
						: undefined,
					categories: cat.split(" > "),
					order: c.order,
					thumb_product: c.images.product || null,
					thumb_model: getModelImage(c.sku, c.images.gallery) || null,
					swatches: c.options.swatches
						.filter(
							(s) =>
								!(
									s.label.match(/handle|interior|reverse/i) ||
									s.selected.startsWith("S-")
								),
						)
						.map((s) => s.selected),
					prices: c.prices,
				});
			});
			return p;
		}, [])
		.sort((a, b) => {
			const groupOrder = [
				"3050",
				"3085",
				"2910",
				"2914",
				"3097",
				"3124",
				"3121",
				"3011",
				"3086",
				"3119",
				"3127",
				"3013",
				"3113",
				"3087",
				"3126",
				"2663",
				"2900",
				"2826",
				"3138",
				"3028",
				"2666",
				"3067",
				"3107",
				"3092",
				"3117",
				"3103",
				"3051",
				"2947",
				"3118",
				"2941",
				"2907",
				"3137",
				"3129",
				"3108",
				"3098",
				"3111",
				"3100",
				"3065",
				"3128",
				"3064",
				"3046",
				"2875",
				"3110",
				"3093",
				"3106",
				"3142",
				"3141",
				"3140",
				"3009",
				"3149",
				"3148",
				"3147",
				"3145",
				"3146",
				"3136",
				"670",
				"620",
				"486",
				"282",
				"281",
			];
			const { group: groupA } = fmtSku(a.sku);
			const { group: groupB } = fmtSku(b.sku);

			const indexA = groupOrder.indexOf(groupA);
			const indexB = groupOrder.indexOf(groupB);
			const defaultOrder = b.sku.localeCompare(a.sku);
			// order by groupOrder and then desc model
			if (indexA >= 0 && indexB >= 0) {
				if (indexA === indexB) return defaultOrder;
				return indexA > indexB ? 1 : -1;
			}
			if (indexA < 0 && indexB >= 0) return 1;
			if (indexA >= 0 && indexB < 0) return -1;
			return defaultOrder;
		});
})();

export async function getRates() {
	return await liveRates;
}

export async function getProduct(
	displayCurrency: string,
	shippingCountry: string,
	sku: string,
	extraCurrencies?: string[],
) {
	const products = await getProducts(
		displayCurrency,
		shippingCountry,
		sku,
		extraCurrencies,
	);
	return products.find((p) => p.sku === sku);
}

function addPriceInfo(
	displayCurrency: string,
	shippingCountry: string,
	extraCurrencies: string[],
	rates: Record<string, number>,
	product: ProductSnapRaw | ProductRaw,
) {
	const isVoucher = Boolean(findSku(product.sku, skus.vouchers));
	const billingCurrency = getPaymentCurrencyByCountry(shippingCountry);
	const shippingCurrency =
		billingCurrency === "GBP"
			? currencies
					.find((c) => c.country === shippingCountry)
					?.currency.toUpperCase() || "GBP"
			: billingCurrency;
	const { prices, ...rest } = product;
	const price = calculateContextualPricing({
		productPrices: prices,
		displayRate: {
			currency: displayCurrency,
			value: rates[displayCurrency] || 0,
		},
		billingRate: {
			currency: billingCurrency,
			value: rates[billingCurrency] || 0,
		},
		shippingRate: {
			currency: shippingCurrency,
			value: rates[shippingCurrency] || 0,
		},
		extraRates: extraCurrencies.map((c) => ({
			currency: c,
			value: rates[c] || 0,
		})),
		shippingCountry,
		digitalGood: isVoucher,
	});

	return { ...rest, price };
}

export async function getProducts(
	displayCurrency: string,
	shippingCountry: string,
	sku?: string[],
	extraCurrencies?: string[],
): Promise<ProductSnap[]>;
export async function getProducts(
	displayCurrency: string,
	shippingCountry: string,
	sku: string,
	extraCurrencies?: string[],
): Promise<Product[]>;
export async function getProducts(
	displayCurrency: string,
	shippingCountry: string,
	sku?: string | string[],
	extraCurrencies?: string[],
) {
	const rates = await liveRates;
	const resolvedSnaps = await snaps;
	const boundAddPriceInfo = addPriceInfo.bind(
		null,
		displayCurrency,
		shippingCountry,
		extraCurrencies || [],
		rates,
	);

	if (Array.isArray(sku)) {
		return sku
			.map((s) => resolvedSnaps.find((p) => p.sku === s))
			.map((p) => (p ? boundAddPriceInfo(p) : undefined));
	}
	if (typeof sku === "string") {
		return (await data).filter((p) => p.sku === sku).map(boundAddPriceInfo);
	}

	return resolvedSnaps.map(boundAddPriceInfo);
}

export function filterProductsBySearchQuery(
	products: ProductSnap[],
	q: string,
) {
	const keywords = fmtSearchQuery(q);
	const items = products.reduce<ProductSnap[]>((p, c) => {
		const cat = c.categories.join(" ").replace(/-/g, " ");
		const sku = c.sku.replace(/-/g, "");
		const match = keywords.every(
			(k) => k.test(c.name) || k.test(cat) || k.test(sku),
		);
		const prev = p.some((i) => i.sku === c.sku);
		!prev && match && p.push(c);
		return p;
	}, []);
	return [{ name: "", items }];
}

function dedupeProducts(products: ProductSnap[]) {
	return products.reduce<ProductSnap[]>((p, c) => {
		const hasProduct = p.some((product) => product.sku === c.sku);
		if (!hasProduct) p.push(c);
		return p;
	}, []);
}

function getCoverProductSku(sku: string) {
	const covers = [
		"3050-33",
		"3085-02",
		"2910-08",
		"2914-15",
		"3097-05",
		"3124-11",
		"3121-01",
		"3011-04",
		"3086-03",
		"3119-02",
		"3127-02",
		"3013-02",
		"3113-09",
		"3087-06",
		"3126-07",
		"2663-01",
		"2900-04",
		"2826-06",
		"3138-01",
		"3028-01",
		"2666-02",
		"3067-01",
		"3107-02",
		"3092-01",
		"3117-01",
		"3103-05",
		"3051-05",
		"2947-08",
		"3118-01",
		"2941-02",
		"2907-02",
		"3137-01",
		"3129-01",
		"3108-03",
		"3098-01",
		"3111-01",
		"3100-01",
		"3065-02",
		"3128-01",
		"3064-04",
		"3046-01",
		"2875-01",
		"3110-03",
		"3093-02",
		"3106-02",
	];
	const group = fmtSku(sku).group;
	return covers.find((sku) => fmtSku(sku).group === group);
}

function filterProductsByStyle(products: ProductSnap[]) {
	return products.reduce<ProductSnap[]>((p, c) => {
		const coverSku = getCoverProductSku(c.sku);
		const heroProduct = products.find((p) => p.sku === coverSku) || c;
		const heroGroup = fmtSku(heroProduct.sku).group;
		const hasProduct = p.some((pp) => fmtSku(pp.sku).group === heroGroup);
		if (!hasProduct) p.push(heroProduct);
		return p;
	}, []);
}

export function filterProductsByCategories(
	products: ProductSnap[],
	categories: string[],
	byStyle?: boolean,
) {
	return categories.reduce(
		(p, c) => {
			let items = dedupeProducts(
				products.filter((i) => i.categories.includes(c)),
			);

			if (byStyle) items = filterProductsByStyle(items);

			if (items.length > 0) p.push({ name: c, items });
			return p;
		},
		[] as Array<{ name: string; items: ProductSnap[] }>,
	);
}

export function groupProductsByCategoryIndex(
	products: ProductSnap[],
	index: number,
	pinnedCategory?: string,
) {
	const order = [
		"women",
		"handbags",
		"top-handle-bags",
		"clutch-bags",
		"day-bags",
		"evening-bags",
		"cross-body-bags",
		"purses",
		"women-card-holders",
		"women-exotic",
		"men",
		"pocket-wallets",
		"breast-pocket-wallets",
		"men-card-holders",
		"men-exotic",
		"accessories",
		"gifts-for-her",
		"gifts-for-him",
		"traviata",
		"juliet",
		"judi",
		"eight-credit-card-wallet",
	];

	return products
		.reduce(
			(p, c) => {
				const cat =
					c.categories[index] ||
					c.categories[c.categories.length - 1];
				if (cat) {
					const prev = p.find((s) => s.name === cat);
					prev
						? prev.items.push(c)
						: p.push({ name: cat, items: [c] });
				}
				return p;
			},
			[] as { name: string; items: ProductSnap[] }[],
		)
		.sort((a, b) => {
			const indexA = order.indexOf(a.name);
			const indexB = order.indexOf(b.name);
			// keep pinned category on top of the list
			if (a.name === pinnedCategory) {
				return -1;
			}
			if (b.name === pinnedCategory) {
				return 1;
			}
			if (indexA >= 0 && indexB >= 0) {
				return indexA - indexB;
			}
			if (indexA < 0 && indexB >= 0) {
				return 1;
			}
			if (indexA >= 0 && indexB < 0) {
				return -1;
			}
			return 0;
		});
}

export async function fmtCartItems(
	displayCurrency: string,
	shippingCountry: string,
	extraCurrencies: string[],
	items: CartItemSnap[],
): Promise<CartItem[]> {
	const products = await getProducts(
		displayCurrency,
		shippingCountry,
		undefined,
		extraCurrencies,
	);
	return items
		.map((i) => {
			const product = products.find((p) => p.sku === i.sku);
			return product && fmtCartItem(product, i);
		})
		.filter((i): i is CartItem => Boolean(i));
}
