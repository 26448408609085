import { Products, Row, ThreeSixty } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

export default function CollectionRoyalTartan() {
	useMetaTags({
		title: "Royal Tartan Bags | Plaid Handbags",
		description:
			"Shop the Launer tartan bag collection. Tradition & heritage combine in a Royal manner with our plaid handbags. Complimentary worldwide shipping on all bags.",
		image: "/images/collections/royal-tartan-banner.jpg",
	});
	return (
		<div className="text-center max-xl:text-sm">
			<Row.WithImage
				reverse
				image="/images/collections/royal-tartan-banner.jpg"
				className="bg-[#1A3D2C] text-sm text-[#E5E5E5]"
				contentClassName={twJoin(
					"place-self-stretch",
					"bg-gradient-to-r from-[#1A3D2C] to-[#012315]",
					"grid items-center sm:max-w-full",
				)}
			>
				<h1 className="ln-title flex flex-col gap-2">
					<span className="text-4xl">Royal Tartan </span>
					<span className="text-base normal-case tracking-wider">
						By Special Commission
					</span>
				</h1>
			</Row.WithImage>
			<Row.Single className="text-base">
				<p>
					Tradition and heritage combine this Autumn in a fitting
					Royal manner with our legacy Traviata and two distinguished
					tartans.
				</p>
				<p>
					Special permission has been granted by Royal Warrant Holder
					Kinloch Anderson to create this handbag icon in The Platinum
					Jubilee tartan and Royal Stewart tartan. Both hold
					remarkable significance and Launer considers them a
					privilege to use.
				</p>
			</Row.Single>
			<Products
				className="container"
				title="Tartan Collection"
				skus={["3050-38", "3050-35", "3050-37", "3050-36"]}
			/>
			<Row.WithImage
				image="/images/collections/royal-tartan-jubilee-tartan.jpg"
				className="bg-[#1A3D2C] text-white"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Platinum Jubilee Tartan</h2>
				</header>
				<p className="my-10">
					This beautiful tartan was created to celebrate Queen
					Elizabeth’s Platinum Jubilee in 2022. Chosen and approved by
					Queen Elizabeth, it is officially registered in The Scottish
					Register of Tartans. The last time a new tartan was
					specifically approved by The Royal Family was when a tartan
					was designed for King George VI.
				</p>
			</Row.WithImage>
			<Row.WithImage
				image="/images/collections/royal-tartan-colour-combination.jpg"
				className="bg-[#F5F3F0] text-black"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Colour Combination</h2>
				</header>
				<p className="my-10">
					A regal alternative presents the exterior in our polished
					house leather combined with The Royal Stewart serving as the
					interior. It’s a stunning combination that is traditional
					and yet modern.
				</p>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/collections/royal-tartan-stewart-tartan.jpg"
				className="bg-[#950503] text-[#EBE7E7]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Royal stewart Tartan</h2>
				</header>
				<p className="my-10">
					Universally loved and recognised, The Royal Stewart tartan
					is steeped in history, dating back to 1831. It is the
					personal tartan of the British Monarch, King Charles and the
					official tartan of The Royal Family.
				</p>
			</Row.WithImage>
			<Row.WithImage
				image="/images/collections/royal-tartan-traviata.jpg"
				className="bg-[#F5F3F0] text-black"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">The Traviata</h2>
				</header>
				<p className="my-10">
					The Traviata is a legend, acclaimed for its structured
					silhouette, exquisite handcrafted detail and signature
					twisted rope emblem. A handbag icon, first introduced in
					1989, achieving International status as the bag of choice
					for Her Majesty Queen Elizabeth II.
				</p>
				<p>
					This iconic style is now majestically reimagined using a
					tartan with special permission from Royal Warrant holder
					Kinloch Anderson.
				</p>
			</Row.WithImage>
			<div
				className={twJoin(
					"max-lg:hidden",
					"aspect-square max-h-[60vh] w-full",
					"bg-gray-200 bg-cover bg-center lg:bg-fixed",
					"bg-[url('/images/collections/royal-tartan-feature-1.jpg')]",
				)}
			/>
			<div className="container my-20 text-center uppercase tracking-widest">
				<h3 className="text-lg font-light">
					<Link
						to="/p/3050-38/traviata-royal-stewart-tartan"
						className="text-[#BBACA0]"
					>
						Royal Stewart Tartan Traviata
					</Link>{" "}
					– through 360º
				</h3>
				<ThreeSixty
					tip="Swipe to rotate"
					className="mx-auto max-w-2xl rounded pb-[100%] md:pb-[700px]"
					image="https://assets.launer.com/images/360/3050-38/{i}_1280x1280.jpeg"
					count={36}
					loop
				/>
			</div>
		</div>
	);
}
