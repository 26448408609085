import { useProductListParams } from "@launerlondon/shop-hooks";
import { twMerge } from "tailwind-merge";
import Row from "./Row";

type Field = { title: string; description: string };

type Data = Record<
	string,
	{
		header: Field;
		divider?: Field & {
			rowOptions?: Partial<{
				reverse: boolean;
				className: string;
				imageClassName: string;
			}>;
		};
		footer?: Array<Field>;
	}
>;

const data: Data = {
	handbags: {
		header: {
			title: "Handbags",
			description:
				"Our women’s bags are a celebration of the finest in British heritage. Luxurious, timeless  pieces, crafted by hand in the heart of England in a listed Edwardian building for more than 80 years.<br/><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		divider: {
			title: "Customisation",
			description:
				"Every article in our collection, from handbags, to purses, wallets and accessories can be reimagined in a colour(s) and leather finish of your choice. It’s a luxury milestone, unique to Launer and handcrafted to our renowned impeccable standard. There is no additional cost for this bespoke service, should you opt for the same calf or patent leather finishes.",
		},
		footer: [
			{
				title: "A handbag by Launer",
				description:
					"The beauty of a British made handbag from Launer lies in its superb craftsmanship and meticulous attention to detail; a leather handbag that endures over time retaining its beauty with a style that transcends trends.",
			},
			{
				title: "Customisation",
				description:
					"The term designer handbag is naturally attributed to our <a href='/c/top-handle-bags'>top handle<a/>, <a href='/c/cross-body-bags'>cross body</a>, <a href='/c/clutch-bags'>clutch</a> and evening styles but at Launer, they represent this and much more. Colour customisation is a service we have pioneered which enables customers to create a piece that is personal to them and can be applied to our luxury handbags as well as our women’s <a href='/c/purses'>purses</a>, <a href='/c/accessories'>leather accessories</a> or even our essential <a href='/c/women-card-holders'>ladies card holders</a>. We can add an additional flourish with gold foil lettering that is hand embossed on selected products.",
			},
			{
				title: "Quiet Luxury",
				description:
					"Launer is proud of its quiet luxury bag status, we make only to order, upholding a sustainable approach, eliminating waste. When you buy a Launer women’s leather bag, it comes with a lifetime warranty, it is neither discounted or sells at a reduced price which gives you complete assurance in our bags – a sign of excellence.",
			},
		],
	},
	"top-handle-bags": {
		header: {
			title: "Top Handle Bags",
			description:
				"Our designer top handle bags are an enduring symbol of ladylike elegance. They elevate any look into a stylish ensemble with a timeless sensibility.<br/><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		divider: {
			title: "Ultimate versatility",
			description:
				"Top handle handbags look every bit as refined when they are worn cross body with their complimentary attachable strap. Invest in your wardrobe with a beautiful leather top handle bag that will remain an essential for years to come.",
		},
	},
	"clutch-bags": {
		header: {
			title: "Clutch Bags",
			description:
				"A designer clutch bag is the perfect accessory by day or night. Beautifully understated, it’s a wardrobe must-have and at Launer has been perfected. Every one of our leather clutch bags comes with an attachable chain or strap as well as a cosmetic mirror, ideal for on the go.<br/><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		footer: [
			{
				title: "A renaissance moment",
				description:
					"The luxury clutch is enjoying something of a renaissance at this time, often carried by Queen Camilla at events and state occasions. At Launer, we are the home of the elegant clutch bag with a host of styles to suit any occasion.",
			},
		],
	},
	"shoulder-bags": {
		header: {
			title: "Shoulder Bags",
			description:
				"The indispensable women’s shoulder bag is a must-have in any wardrobe, a beautifully versatile accessory by day or by night. At Launer, as you would expect, our leather shoulder bags are refined and supremely crafted by our skilled artisans in our owned factory using the finest Italian leather. Adopting many guises, they come in a variety of sizes and styles from micro to shopper with stunning elegance.</br><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		footer: [
			{
				title: "Deluxe day bags",
				description:
					"Our top handle bags all feature an attachable shoulder strap option and double handle styles are designed to also be carried by hand. From the ladylike poise of the Turandot to the casual luxe of Elizabeth, the signature house rope emblem is adorned along with a complimentary cosmetic mirror and protective dust bag making these the ultimate in designer shoulder bags.",
			},
			{
				title: "Sleek yet spacious",
				description:
					"For those seeking a small shoulder bag then Angelika or Emma are perfect whilst those wanting larger shoulder bags have Lydia and Sue to accommodate their needs. When you buy from Launer you are investing in a shoulder purse that will be part of your wardrobe for years to come.",
			},
		],
	},
	"evening-bags": {
		header: {
			title: "Evening Bags",
			description:
				"For those seeking the ultimate designer evening bag, Launer has a stunning array that are truly exquisite. Crafted in England by hand, our collection includes metallic <a href='/c/clutch-bags'>clutches</a>, patent <a href='/c/shoulder-bags'>shoulder bags</a> and elegant <a href='/c/top-handle-bags'>top handle</a> styles.<br/><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		divider: {
			title: "An item to treasure",
			description:
				"Our range is perfection: every piece is an iconic accessory that will ensure you make a stellar entrance to any event. Our women’s evening bag styles transcend time; a collectable item that goes beyond an everyday staple.",
			rowOptions: {
				reverse: true,
			},
		},
	},
	"cross-body-bags": {
		header: {
			title: "Cross Body Bags",
			description:
				"The essential women’s cross body bag becomes an elevated style statement at Launer. Every one of our luxury leather cross body bags is delivered with a complimentary cosmetic mirror, adjustable strap and embossed leather plaque. A structured form also ensures this style retains a sophisticated air with a range of sizes to offer.<br/><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		footer: [
			{
				title: "Unprecedented degree of workmanship",
				description:
					"Every piece is handcrafted in England by skilled artisans using traditional methods. Remember, our colour customisation service can transform your cross body purse into a unique timeless piece.",
			},
		],
	},
	purses: {
		header: {
			title: "Purses",
			description:
				"An exquisite handbag requires an exquisite purse to match. At Launer, we have a beautiful array of women’s purses which are handmade using traditional methods and luxury leather. Designed in different sizes to fit into our bags, our collection includes card cases alongside coin purses and pouches, all featuring our signature hallmarks.<br/><br/>Many elements of our purses can be customised; including colour, material and fittings.",
		},
	},
	"women-card-holders": {
		header: {
			title: "Women’s card holders",
			description:
				"The essential ladies card holder is a refined alternative to a purse, reassuringly handmade in England. Our leather card holders look stylish and elegant with the ability to store multiple cards and place discreetly in a Launer handbag or clothing pocket.<br/><br/>Many elements of our card holders can be customised; including colour and material.",
		},
		footer: [
			{
				title: "The perfect gift",
				description:
					"An indispensable, everyday accessory that is increasingly used, retaining a feminine appeal. The women’s credit card case makes an ideal gift for loved ones.",
			},
		],
	},
	"men-card-holders": {
		header: {
			title: "Men’s card holders",
			description:
				"A must-have item for any discerning man has to be the cardholder wallet, a refined option reassuringly handmade in England. Our leather card holder looks stylish and elegant with the ability to store multiple cards and place discreetly in a jacket, coat or trouser pocket.<br/><br/>Many elements of our card holders can be customised; including colour and material.",
		},
		footer: [
			{
				title: "The perfect gift",
				description:
					"An indispensable, everyday accessory that is increasingly used, at Launer our designer cardholders are a distinguished affair. Making a considered gift; our luxury cardholder is presented in a gift box with a protective dust cover.",
			},
		],
	},
	wallets: {
		header: {
			title: "Wallets",
			description:
				"Our storied brand, as you would expect, boasts a distinguished array of men’s wallets. Often referred to as the finest designer wallets, our collection exudes an understated luxury. Every piece is handmade in England featuring our signature turned-edge leather work. There is a style to suit all men and their requirements including bifold designs, coin purses, classic pocket wallets and breast pocket wallets.<br/><br/>Many elements of our wallets can be customised; including colour, material and fittings.",
		},
		footer: [
			{
				title: "A considered gift",
				description:
					"A luxury British leather wallet has to be one of the most appreciated gifts and is a highly sought after purchase.",
			},
		],
	},
	luggage: {
		header: {
			title: "Luggage",
			description:
				"Launer London’s range of luxury luggage is designed with the discerning traveller in mind. Embark on your next journey across the globe with leather luggage handmade in Britain using only the finest materials of the highest quality.<br/><br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		footer: [
			{
				title: "Elevated travel pieces",
				description:
					"Find scotch grain bearing the hallmarks of Launer, synonymous with eight decades of handcrafted work. Our designer luggage collection features both men’s and women’s luggage sets. Find travel and cabin bags, the perfect companions for your next excursion.",
			},
			{
				title: "Discover more distinguished pieces",
				description:
					"Complete the set with <a href='/c/wash-bags'>wash bags</a> and <a href='/c/portfolio-cases'>leather portfolios</a>, and accessorise with <a href='/c/luggage-accessories'>luggage tags</a>, <a href='/s/passport-holder'>passport holders</a> and more. Let Launer London be the finest companion as you explore continents, coastlines and cultures.",
			},
		],
	},
	"travel-bags": {
		header: {
			title: "Travel Bags",
			description:
				"Let the journey begin with a sumptuous travel bag designed with tradition in mind for the modern-day traveller. Coming in three sizes, Launer London designer travel bags are the ideal weekend or overnight bag for men and women alike.<br/><br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		footer: [
			{
				title: "Handcrafted heritage luggage",
				description:
					"Take luggage to a sublime level with meticulous attention to detail, considered design and a refined engineered structure. Our luxury travel bags are crafted in a new enduring leather, scotch grain.",
			},
			{
				title: "Sublime luggage",
				description:
					"Whether you are a travel connoisseur looking for a man’s leather weekend bag, a lady’s overnight bag or a cabin bag, our handmade in Britain travel bags are the perfect companion: beautifully functional and crafted to last a lifetime.",
			},
		],
	},
	"cabin-bags": {
		header: {
			title: "Cabin Bags",
			description:
				"When boarding a flight to your next far-away destination, a Launer London luxury cabin bag is the perfect companion. There are options in the ideal hand luggage size and crafted in new enduring scotch grain leather, there is simply no better way to travel in style.  <br/> <br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		footer: [
			{
				title: "British handmade luggage",
				description:
					"Our cabin luggage bags are handmade in Britain with precise attention to detail and a refined engineered structure. Whether you are looking for a man’s cabin bag or a woman’s carry-on bag, we are certain to have the best accompaniment for your travels.",
			},
			{
				title: "Accessorise with Hatherton",
				description:
					"Complement your new leather luggage with a <a href='/c/wash-bags'>wash bag</a> or <a href='/c/luggage-accessories'>accessories</a> such as <a href='/c/luggage-accessories'>luggage tags</a> and <a href='/s/passport-holder'>passport holders</a>. If you are travelling for business, our leather folios may be just what you are seeking.",
			},
		],
	},
	"luggage-accessories": {
		header: {
			title: "Luggage accessories",
			description:
				"The discerning globetrotter requires considered luggage accessories to accompany them on their travels. A curated selection awaits with Launer London, from a designer passport holder to travel wallets and luggage tags, all crafted from the finest leather.<br/><br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		footer: [
			{
				title: "Hatherton Wash bag",
				description:
					"Designed to complement our travel and cabin bags, finish off your collection with a wash bag. Safely store your most valuable travel possessions in this spacious, luxurious item.",
			},
		],
	},
	"portfolio-cases": {
		header: {
			title: "Portfolio Cases",
			description:
				"The undisputed beauty of Launer’s leather craftsmanship is evident on our Hatherton portfolio cases. Demonstrating a timeless heritage that excels for travel or for business in our new enduring scotch grain in a choice of four ultimate colours.<br/><br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		divider: {
			title: "Indispensable for everyday journeys",
			description:
				"If you seek to marry style, function, durability and a streamlined appearance then you have arrived at the right destination. Equally significant is the safe storage of your laptop, tablet and documents, and our leather folio cases have this mastered with an engraved padlock and key or reverse circular lock with padlock and key.",
			rowOptions: {
				reverse: true,
				imageClassName: "bg-contain bg-[#F2EEED]",
				className: "bg-[#F2EEED]",
			},
		},
		footer: [
			{
				title: "Sizes to suit any excursion",
				description:
					"They come in many guises: small to extra large, zipped or the option of retractable handles on the laptop and tablet cases, which all bear the renowned hallmarks Launer is lauded for. True iconic pieces with a contemporary appeal, a leather portfolio is also ideal as a gift for him or her.",
			},
		],
	},
	"wash-bags": {
		header: {
			title: "Wash Bags",
			description:
				"Wash bags are a must-have for any frequent traveller, and are now reimagined in elegant style. Launer London's wash bag for men and women alike seamlessly blends style and function in a luxurious fashion.</br><br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		footer: [
			{
				title: "Store essentials",
				description:
					"Our toiletry bags are handcrafted in enduring scotch grain of the highest quality. For travels and overnight stays, it’s a must-have to store essentials with a spacious interior and water-resistant lining.",
			},
			{
				title: "A gift for the discerning",
				description:
					"Seeking that gift with a luxury touch? Our leather wash bags make a gift they will appreciate, whatever the occasion.",
			},
		],
	},
	traviata: {
		header: {
			title: "Traviata",
			description:
				"The Traviata is a legend, acclaimed for its structured silhouette, exquisite, handcrafted detail and signature twisted rope emblem. First introduced in 1989, it achieved International acclaim as the handbag of choice for Her Late Majesty The Queen.",
		},
		divider: {
			title: "Since 1989",
			description:
				"This impeccable design transcends time remaining stylish and contemporary, the hallmark of a true investment piece.<br/><br/>It has been featured in films, illustrations, on cakes and in other guises resulting in its status as a style icon.",
		},
		footer: [
			{
				title: "A handbag by Launer",
				description:
					"The beauty of a British made handbag from Launer lies in its superb craftsmanship and meticulous attention to detail; a leather handbag that endures over time retaining its beauty with a style that transcends trends.",
			},
			{
				title: "Customisation",
				description:
					"The term designer handbag is naturally attributed to our <a href='/c/top-handle-bags'>top handle<a/>, <a href='/c/cross-body-bags'>cross body</a>, <a href='/c/clutch-bags'>clutch</a> and evening styles but at Launer, they represent this and much more. Colour customisation is a service we have pioneered which enables customers to create a piece that is personal to them and can be applied to our luxury handbags as well as our women’s <a href='/c/purses'>purses</a>, <a href='/c/accessories'>leather accessories</a> or even our essential <a href='/c/women-card-holders'>ladies card holders</a>. We can add an additional flourish with gold foil lettering that is hand embossed on selected products.",
			},
			{
				title: "Quiet Luxury",
				description:
					"Launer is proud of its quiet luxury bag status, we make only to order, upholding a sustainable approach, eliminating waste. When you buy a Launer women’s leather bag, it comes with a lifetime warranty, it is neither discounted or sells at a reduced price which gives you complete assurance in our bags – a sign of excellence.",
			},
		],
	},
	judi: {
		header: {
			title: "Judi",
			description:
				"The Judi is a scaled down version of the iconic Traviata. Beautifully proportioned, this petite model is impeccably refined and carries our signature hallmarks of the twisted rope emblem, structured silhouette and top handle. Created in 1995 it is one of our most popular bags with a Royal seal of approval – The Queen Consort, Camilla often carries the Judi at functions and official engagements.",
		},
		footer: [
			{
				title: "A handbag by Launer",
				description:
					"The beauty of a British made handbag from Launer lies in its superb craftsmanship and meticulous attention to detail; a leather handbag that endures over time retaining its beauty with a style that transcends trends.",
			},
			{
				title: "Customisation",
				description:
					"The term designer handbag is naturally attributed to our <a href='/c/top-handle-bags'>top handle<a/>, <a href='/c/cross-body-bags'>cross body</a>, <a href='/c/clutch-bags'>clutch</a> and evening styles but at Launer, they represent this and much more. Colour customisation is a service we have pioneered which enables customers to create a piece that is personal to them and can be applied to our luxury handbags as well as our women’s <a href='/c/purses'>purses</a>, <a href='/c/accessories'>leather accessories</a> or even our essential <a href='/c/women-card-holders'>ladies card holders</a>. We can add an additional flourish with gold foil lettering that is hand embossed on selected products.",
			},
			{
				title: "Quiet Luxury",
				description:
					"Launer is proud of its quiet luxury bag status, we make only to order, upholding a sustainable approach, eliminating waste. When you buy a Launer women’s leather bag, it comes with a lifetime warranty, it is neither discounted or sells at a reduced price which gives you complete assurance in our bags – a sign of excellence.",
			},
		],
	},
};

function useSection() {
	const { search, slug, byStyle } = useProductListParams();
	const name = search || slug;
	return name && data[name] ? { ...data[name], name, byStyle } : undefined;
}

const Header: React.FC = () => {
	const section = useSection();
	if (!section?.header) return null;
	return (
		<div className="bg-gray-50">
			<div className="container grid gap-4 max-lg:py-8 lg:grid-cols-2">
				<div className="grid items-center">
					<div className="space-y-2">
						<h1 className="ln-subtitle">
							{section.header.title}{" "}
							{section.byStyle && "by style"}
						</h1>
						<p
							className="text-sm lg:max-w-xl [&_a]:underline"
							dangerouslySetInnerHTML={{
								__html: String(section.header.description),
							}}
						/>
					</div>
				</div>
				<div
					className="aspect-[4/2] bg-gray-50 bg-cover bg-center max-lg:hidden"
					style={{
						backgroundImage: `url(/images/hero/${section.name}.webp)`,
					}}
				/>
			</div>
		</div>
	);
};

const Divider: React.FC<{ className?: string }> = (props) => {
	const section = useSection();
	if (!section?.divider) return null;
	const opts = section.divider.rowOptions;
	return (
		<Row.WithImage
			reverse={opts?.reverse}
			className={twMerge(
				"overflow-hidden rounded-lg bg-gray-50 text-center",
				opts?.className,
				props.className,
			)}
			containerClassName="!px-0"
			imageClassName={twMerge("bg-cover bg-right", opts?.imageClassName)}
			contentClassName="max-w-lg"
			image={`/images/hero/${section.name}-divider.webp`}
		>
			<h3 className="text-lg font-[400]">{section.divider.title}</h3>
			<p
				className="text-sm [&_a]:underline"
				dangerouslySetInnerHTML={{
					__html: String(section.divider.description),
				}}
			/>
		</Row.WithImage>
	);
};

const Footer: React.FC = () => {
	const section = useSection();
	if (!section?.footer) return null;

	return (
		<div className="border-t">
			<div className="container grid gap-8 py-10 lg:grid-cols-3">
				{section.footer?.map((s) => (
					<div key={s.title} className="space-y-2">
						<h4>{s.title}</h4>
						<p
							className="text-xs text-gray-500 [&_a]:underline"
							dangerouslySetInnerHTML={{
								__html: String(s.description),
							}}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default { Header, Divider, Footer, keys: Object.keys(data) };
