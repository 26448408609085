import { Popover, Transition } from "@headlessui/react";
import { getSwatchGroupLabel, useLocalization } from "@launerlondon/l10n";
import {
	groupProductsByCategoryIndex,
	ProductSnap,
} from "@launerlondon/products";
import { getSwatchColor, swatches } from "@launerlondon/shared";
import { routes } from "@launerlondon/shop-router";
import { Currency, RootState } from "@launerlondon/shop-types";
import cx from "classnames";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { getMenuParentCategories } from "../lib/menu";
import Filter, { FilterOption, FilterPriceValue } from "./MenuFilterItem";
import { useProductListParams } from "@launerlondon/shop-hooks";

type Filter = {
	range?: string;
	section?: string;
	category?: string;
	material?: string;
	color?: string;
	price: FilterPriceValue;
};
type ProductSection = { name: string; items: ProductSnap[] };
type Props = {
	hidePresetFilters?: boolean;
	products: ProductSnap[];
	onChange: (products: ProductSection[]) => void;
};

function addAllSelector(options: FilterOption[], count: number) {
	return [{ key: "all", name: "all", count }, ...options];
}
function getCategoryOptions(products: ProductSnap[], keyIndex: number) {
	return addAllSelector(
		products.reduce<FilterOption[]>((p, c) => {
			/**
			 * after index two of [women,handbags,top-handle-body-bags,cross-body-bags]
			 * use all categories
			 **/
			const keys =
				keyIndex < 2
					? [c.categories[keyIndex] || ""]
					: c.categories.slice(keyIndex);
			if (!keys.filter(Boolean).length) return p;

			keys.forEach((key) => {
				const prev = p.find((i) => i.key === key);
				if (prev) {
					prev.count += 1;
				} else {
					p.push({ key, name: key, count: 1 });
				}
			});
			return p;
		}, []),
		products.length,
	);
}
function getMaterialOptions(products: ProductSnap[]) {
	return addAllSelector(
		products.reduce<FilterOption[]>((p, c) => {
			const groups = c.swatches.reduce<string[]>((p, c) => {
				const g = c.split("-")[0] || "";
				!p.includes(g) && p.push(g);
				return p;
			}, []);
			groups.forEach((key) => {
				if (!key) return p;
				const prev = p.find((i) => i.key === key);
				if (prev) {
					prev.count += 1;
				} else {
					p.push({
						key,
						name: getSwatchGroupLabel(key),
						count: 1,
					});
				}
			});
			return p;
		}, []),
		products.length,
	);
}
function getColorOptions(products: ProductSnap[]) {
	return addAllSelector(
		products
			.reduce<FilterOption[]>((p, c) => {
				const colors = c.swatches.reduce<string[]>((p, c) => {
					const color = getSwatchColor(swatches, c);
					color && !p.includes(color) && p.push(color);
					return p;
				}, []);
				colors.forEach((key) => {
					if (!key) return p;
					const prev = p.find((i) => i.key === key);
					if (prev) {
						prev.count += 1;
					} else {
						p.push({
							key,
							name: key,
							count: 1,
						});
					}
				});
				return p;
			}, [])
			.sort((a, b) => a.key.localeCompare(b.key)),
		products.length,
	);
}
function getPriceOptions(products: ProductSnap[], currency: Currency) {
	const prices = products.map(
		(p) => p.price[currency.toLowerCase() as "gbp"],
	);
	if (prices.length === 0)
		return { values: [0, 0], min: 0, max: 100, step: 50 };
	const rawMin = Math.min(...prices);
	let rawMax = Math.max(...prices);
	if (rawMin >= rawMax) {
		rawMax = rawMin + 10;
	}
	const step = 50;
	const min = Math.floor(rawMin / step) * step;
	const max = Math.ceil(rawMax / step) * step;
	const r: FilterPriceValue = {
		values: [min, max],
		step,
		min,
		max,
	};
	return r;
}
function filterProductsFn(
	products: ProductSnap[],
	filter: Filter,
	currency: string,
	skip = "",
) {
	return products.filter((p) => {
		const hasRange =
			!/range/.test(skip) && filter.range
				? p.categories.includes(filter.range)
				: true;
		const hasSection =
			!/range|section/.test(skip) && filter.section
				? p.categories.includes(filter.section)
				: true;
		const hasCategory =
			!/range|section|category/.test(skip) && filter.category
				? p.categories.includes(filter.category)
				: true;
		const hasMaterial =
			!/material/.test(skip) && filter.material
				? p.swatches.some((s) => s.split("-")[0] === filter.material)
				: true;
		const hasColor =
			!/material|color/.test(skip) && filter.color
				? p.swatches.some(
						(s) => getSwatchColor(swatches, s) === filter.color,
				  )
				: true;
		const hasPrice =
			!/range|section|category|material|color|price/.test(skip) &&
			filter.price
				? p.price[currency.toLowerCase() as "gbp"] >=
						(filter.price.values[0] || 0) &&
				  p.price[currency.toLowerCase() as "gbp"] <=
						(filter.price.values[1] || Infinity)
				: true;
		const pass =
			hasRange &&
			hasSection &&
			hasCategory &&
			hasMaterial &&
			hasColor &&
			hasPrice;
		return pass;
	});
}

function isLargeViewport() {
	return document.documentElement.clientWidth >= 1024;
}

function showSubGroups(section?: string) {
	return ["handbags", "wallets"].includes(String(section));
}

const MenuFilter: React.FC<Props> = (props) => {
	const { l10n } = useLocalization();
	const loading = false;
	const currency = useSelector((s: RootState) => s.locale.currency);
	const [showFilter, setShowFilter] = useState(isLargeViewport());
	const [menuCategories, setMenuCategories] = useState<
		Array<string | undefined>
	>([]);
	const productListRoute = useMatch(routes.productList);
	const { slug, search, byStyle } = useProductListParams();

	useEffect(() => {
		if (productListRoute) {
			getMenuParentCategories(`/c/${slug}`).then((items) => {
				setMenuCategories(items.map((i) => i.url?.replace("/c/", "")));
			});
			return;
		}
		// if search path reset menuCategories
		setMenuCategories([]);
	}, [slug, search]);

	const [filter, setFilter] = useState<Filter>({
		price: {
			values: [0, 100],
			min: 0,
			max: 100,
			step: 50,
		},
	});

	const filterProducts = useCallback(
		(skip?: string) =>
			filterProductsFn(props.products, filter, currency, skip),
		[props.products, filter, currency],
	);

	const refreshPrices = useCallback(
		() => getPriceOptions(filterProducts("price"), currency),
		[getPriceOptions, filterProducts, currency],
	);

	const range = getCategoryOptions(filterProducts("range"), 0);
	const sections = getCategoryOptions(filterProducts("section"), 1);
	const categories = getCategoryOptions(filterProducts("category"), 2);
	const materials = getMaterialOptions(filterProducts("material"));
	const colors = getColorOptions(filterProducts("color"));
	const results = filterProducts();

	const resetFilter = useCallback(() => {
		setFilter(() => ({
			range: menuCategories[0],
			section: menuCategories[1],
			category: menuCategories[2],
			price: refreshPrices(),
		}));
	}, [props.products, menuCategories, refreshPrices]);

	useEffect(resetFilter, [props.products, menuCategories]);

	useEffect(() => {
		setFilter((f) => ({
			...f,
			price: refreshPrices(),
		}));
	}, [loading]);

	useEffect(() => {
		if (loading) return;
		setFilter((f) => ({
			...f,
			price: refreshPrices(),
		}));
	}, [
		filter.range,
		filter.section,
		filter.category,
		filter.material,
		filter.color,
	]);

	useEffect(() => {
		if (loading) return;
		setFilter((f) => {
			if (!categories.length) f.category = undefined;
			if (!materials.length) f.material = undefined;
			if (!colors.length) f.color = undefined;
			return f;
		});
	}, [loading, categories, materials, colors]);

	useEffect(() => {
		let index = filter.category || showSubGroups(filter.section) ? 2 : 1;
		// sub-divide hybrid variants for cross-body-bags
		if (filter.category === "cross-body-bags") {
			index = 3;
		}
		const p = groupProductsByCategoryIndex(results, index, filter.category);
		props.onChange(p);
	}, [props.products, filter]);

	useLayoutEffect(() => {
		const onResize = () => setShowFilter(isLargeViewport());
		addEventListener("resize", onResize);
		return () => removeEventListener("resize", onResize);
	}, []);

	if (loading) return null;

	return (
		<div className="w-full bg-white">
			<div className={cx("py-4 text-sm", "lg:py-0")}>
				<button
					className="w-full text-left font-normal lg:hidden"
					onClick={() => setShowFilter((s) => !s)}
				>
					{l10n.getString("product-filter--title")} ({results.length})
				</button>
				<Transition
					show={showFilter}
					enterFrom="-translate-y-10 opacity-0"
					leaveTo="-translate-y-10 opacity-0"
					className={cx(
						"transition",
						"fixed inset-0 z-40 flex flex-col overflow-auto bg-white",
						"lg:static",
					)}
				>
					<Popover.Group
						className={cx(
							"flex flex-1 flex-col gap-4",
							"lg:flex-row lg:flex-wrap lg:items-center lg:gap-8 lg:py-4",
						)}
					>
						<h2
							className={cx(
								"sticky top-0 border-b bg-white p-4 text-center uppercase tracking-widest",
								"lg:hidden",
							)}
						>
							{l10n.getString("product-filter--title")}
						</h2>
						<div
							className={cx(
								"flex flex-col divide-y px-4",
								"lg:flex-row lg:gap-8 lg:divide-y-0 lg:px-0",
							)}
						>
							<Filter
								show={props.hidePresetFilters ? false : true}
								type="category"
								label={l10n.getString("product-filter--range")}
								defaultValue={filter.range}
								options={range}
								onChange={(v) =>
									setFilter((f) => ({
										...f,
										range: v,
										section: undefined,
										category: undefined,
									}))
								}
							/>
							<Filter
								show={() => {
									if (props.hidePresetFilters) {
										return menuCategories[1] === undefined;
									}
									return filter.range && sections.length;
								}}
								type="category"
								label={l10n.getString("product-filter--type")}
								defaultValue={filter.section}
								options={sections}
								onChange={(v) =>
									setFilter((f) => ({
										...f,
										section: v,
										category: undefined,
									}))
								}
							/>
							<Filter
								show={() => {
									if (props.hidePresetFilters) {
										return (
											showSubGroups(filter.section) &&
											menuCategories[2] === undefined
										);
									}
									return filter.section && categories.length;
								}}
								type="category"
								label={l10n.getString(
									"product-filter--category",
								)}
								defaultValue={filter.category}
								options={categories}
								onChange={(v) =>
									setFilter((f) => ({
										...f,
										category: v,
									}))
								}
							/>
							<Filter
								type="material"
								show={!byStyle}
								label={l10n.getString(
									"product-filter--material",
								)}
								defaultValue={filter.material}
								options={materials}
								onChange={(v) =>
									setFilter((f) => ({
										...f,
										material: v,
										color: undefined,
									}))
								}
							/>
							<Filter
								type="color"
								show={!byStyle}
								label={l10n.getString("product-filter--color")}
								defaultValue={filter.color}
								options={colors}
								onChange={(v) =>
									setFilter((f) => ({
										...f,
										color: v,
									}))
								}
							/>
							<Filter
								type="price"
								label={l10n.getString("product-filter--price")}
								defaultValue={filter.price}
								onChange={(values) =>
									setFilter((f) => ({
										...f,
										price: { ...f.price, values },
									}))
								}
							/>
						</div>
						<div
							className={cx(
								"fixed right-4 top-4",
								"flex gap-4 lg:static lg:ml-auto",
							)}
						>
							<div className="hidden text-[#9B9797] lg:block">
								{l10n.getString("product-filter--results")}{" "}
								{results.length}
							</div>
							<button
								className="text-left text-[#362E2E] underline lg:ml-4 lg:mt-0"
								onClick={resetFilter}
							>
								{l10n.getString("product-filter--reset")}
							</button>
						</div>
						<button
							className="button mb-10 mt-auto tracking-widest lg:hidden"
							onClick={() => setShowFilter(false)}
						>
							{l10n.getString("product-filter--show-results")} (
							{results.length})
						</button>
					</Popover.Group>
				</Transition>
			</div>
		</div>
	);
};

export default MenuFilter;
